import { Injectable } from '@angular/core';
import { Producto } from '../interfaces/producto';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductosTraerService {
  API_URI = environment.apiUrl;
  constructor(
    private http :HttpClient
  ) { }
  ProductosTraer():Observable<Producto[]>{
    return this.http.get<Producto[]>(`${this.API_URI}/producto_new`);
  }
}
