<div class="mobile-menu__backdrop" (click)="menu.close()"></div>
<div class="mobile-menu__body" #body>
    <button class="mobile-menu__close" type="button" (click)="menu.close()">
        <app-icon icon="cross-12"></app-icon>
    </button>

    <div class="mobile-menu__conveyor" [style.transform]="'translateX(-' + currentLevel * 100 + '%)'" #conveyor>
        <app-mobile-menu-panel label="Menu">
            <app-mobile-menu-settings></app-mobile-menu-settings>
            <div class="mobile-menu__divider"></div>
            <app-mobile-menu-indicators></app-mobile-menu-indicators>
            <div class="mobile-menu__divider"></div>
            <app-mobile-menu-links [items]="links" (itemClick)="onLinkClick($event)"></app-mobile-menu-links>

            <div class="mobile-menu__spring"></div>
            <div class="mobile-menu__divider"></div>
            <a class="mobile-menu__contacts" href="">
                <div class="mobile-menu__contacts-subtitle">Contáctenos</div>
                <div class="mobile-menu__contacts-title">(01)396-1460</div>
                <div class="mobile-menu__contacts-title">943-669-482</div>
            </a>
        </app-mobile-menu-panel>

        <ng-container #panelsContainer></ng-container>
    </div>
</div>
