import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drop-carrito',
  templateUrl: './drop-carrito.component.html',
  styleUrls: ['./drop-carrito.component.scss']
})
export class DropCarritoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
