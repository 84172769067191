import { Component, forwardRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl,FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Vehicle } from '../../../../interfaces/vehicle';
import { VehicleApi } from '../../../../api/base';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SidebarService } from '../../../../modules/shop/services/sidebar.service'
import { FiltrosService } from '../../../../modules/shop/services/filtros.service';
type ChangeFn = (_: Vehicle) => void;

type TouchedFn = () => void;

interface VehicleSelectItemDef<T = any> {
    key: string;
    label: string;
    placeholder: string;
    optionsSource: (...args: any[]) => Observable<T[]>;
    serializeOptionFn?: (option: T, item: VehicleSelectItem<T>) => string;
    deserializeOptionFn?: (value: string, item: VehicleSelectItem<T>) => T;
}

interface VehicleSelectItem<T = any> extends VehicleSelectItemDef<T> {
    loading: boolean;
    options: T[];
    load: Subject<void>;
}

function makeItem<T>(itemDef: VehicleSelectItemDef<T>): VehicleSelectItem<T> {
    return {...itemDef, loading: false, options: [], load: new Subject<void>()};
}

@Component({
    selector: 'app-vehicle-select',
    templateUrl: './vehicle-select.component.html',
    styleUrls: ['./vehicle-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VehicleSelectComponent),
            multi: true,
        },
    ],
})
export class VehicleSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    private destroy$: Subject<void> = new Subject<void>();

    changeFn: (_: any) => void = () => {};
    touchedFn: () => void = () => {};
    value:any;
    idmarca2:any;
    idparte:any;
    idparametros:any;

    form: FormGroup;

    constructor(
        private vehicleApi: VehicleApi,
        private fb: FormBuilder,
        public fil: FiltrosService,
        private sidebarService: SidebarService
    ) { 
        this.buildForm();
    }
    clases:any[]=[];
    marcas:any[]=[];
    marcas2:any[]=[];
    partes:any[]=[];
    modelos:any[]=[];

    ngOnInit(): void {
        this.sidebarService.selectClase().subscribe(list=>{
            console.log(list);
            this.clases=list;
        })

        this.sidebarService.selectMarca2().subscribe(list=>{
            // console.log(list);
            this.marcas2=list;
        })

        this.sidebarService.selectParte().subscribe(list=>{
            // console.log(list);
            this.partes=list;
        })
    }
    disabled_marca:boolean=true;
    cla:boolean=false;
    private buildForm() {
        this.form = this.fb.group({
          //clase: ['', [Validators.required]],
          clase: ['', []],
          marca: ['', []],
          marca2: ['', []],
          parte: ['', []],
          modelo: ['', []],
        });
        this.form.get('clase').setValue('');
        this.form.get('marca').setValue('');
        this.form.get('parte').setValue('');
        this.form.get('modelo').setValue('');

        // this.form.get('clase').valueChanges.subscribe(x=>{
        //     console.log("-->",x);
        //     this.form.get('marca').setValue('');
        //     if(!x){
        //         this.disabled_marca=true;
        //     }else{
        //         this.cla=true;
        //         this.disabled_marca=false;
        //         this.sidebarService.selectMarca(x).subscribe(list=>{
        //             this.marcas=list;
        //             this.cla=false;
                    
        //         })

        //     }
        // });

        this.form.get('marca2').valueChanges.subscribe(x=>{
            this.idmarca2=x;
            //this.form.get('marca').setValue('');
            this.idparametros=this.idmarca2+"_"+this.idparte;
            console.log("idparametros:",this.idparametros)

            this.form.get('modelo').setValue('');
            if(!this.idparametros){
                this.disabled_marca=true;
            }else{
                this.cla=true;
                this.disabled_marca=false;
                this.sidebarService.selectModelo(this.idparametros).subscribe(list=>{
                    this.modelos=list;
                    this.cla=false;
                    
                })

            }
            
        });


        // this.form.get('parte').valueChanges.subscribe(x=>{
        //     console.log("-->",x);
        //     this.idparte=x;
        //     this.idparametros=this.idmarca2+"_"+this.idparte;
        //     console.log("idparametros:",this.idparametros)

        //     this.form.get('modelo').setValue('');
        //     if(!this.idparametros){
        //         this.disabled_marca=true;
        //     }else{
        //         this.cla=true;
        //         this.disabled_marca=false;
        //         this.sidebarService.selectModelo(this.idparametros).subscribe(list=>{
        //             this.modelos=list;
        //             this.cla=false;
                    
        //         })

        //     }
        // });

        
        this.form.valueChanges.subscribe(data=>{
            console.log("data:",data)
            if(this.form.valid){
                this.changeFn(data);
            }else{
                this.changeFn('');
            }
        })
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
    this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchedFn = fn;
    }

    
    
}
