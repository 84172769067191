import { ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { Producto } from '../../../interfaces/producto';
import { CartService } from '../../../services/cart.service';
import { CarritoService } from '../../../services/carrito.service';
import { ProductoCarrito } from '../../../services/carrito.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appAgregarAlCarrito]',
  exportAs: 'agregarAlCarrito',
})
export class AgregarAlCarritoDirective implements OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

    inProgress = false;

    constructor(
        private cart: CarritoService,
        private cd: ChangeDetectorRef,
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    add(product: Producto): void {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        let x: ProductoCarrito
        x={
          id: product.id,
          imagen: product.imagen,
          name: product.name,
          price: product.price
          };
        this.cart.addCart(product).pipe(takeUntil(this.destroy$)).subscribe({
            complete: () => {
                this.inProgress = false;
                this.cd.markForCheck();
            },
        });
    }

}
