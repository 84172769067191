import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
//import { Producto } from '../interfaces/producto';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { map } from 'rxjs/operators';
export interface ProductoCarrito {
  id?: number;
  imagen?: string;
  name?: string;
  cantidad?: number;
  price?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  private products_cart: ProductoCarrito[] = [];
  /*
  private cart = new BehaviorSubject<ProductoCarrito[]>(this.products_cart);
  cart$ = this.cart.asObservable();
  */


  private itemsSubject$: BehaviorSubject<ProductoCarrito[]> = new BehaviorSubject(this.products_cart);
  readonly items$: Observable<ProductoCarrito[]> = this.itemsSubject$.asObservable();


  private onAddingSubject$: Subject<ProductoCarrito> = new Subject();
  readonly onAdding$: Observable<ProductoCarrito> = this.onAddingSubject$.asObservable();
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (isPlatformBrowser(this.platformId)) {
        this.load();
        this.calc();
    }
  }
  
  addCart_plus(id:number){
    var cant:number=0;
    for(let i=0; i < this.products_cart.length; i++) {
      if(this.products_cart[i].id==id){
        cant = this.products_cart[i].cantidad; 
        cant=cant+1;
        this.products_cart[i].cantidad=cant;
      }

    }
    //this.cart.next(this.products_cart); 
    this.save();
    this.calc();
  }
  anadirCantidadExacta(producto : ProductoCarrito, y:number):Observable<ProductoCarrito>{
    return timer(350).pipe(map(() => {
      this.onAddingSubject$.next(producto);
      var p:string= 'no existe';
      for(let i=0; i < this.products_cart.length; i++) {
        //console.log(this.pedidoArray[i].cantidad);
        if(this.products_cart[i].id==producto.id){
          this.products_cart[i].cantidad=y;
          p='si existe';
        }

      }
      if(p == 'no existe'){
        
          
        let x: ProductoCarrito;
        x={
          id: producto.id,
          imagen: producto.imagen,
          name: producto.name,
          cantidad: y,
          price: producto.price
          };
        this.products_cart.push(x);        
          
      
      }
      //this.cart.next(this.products_cart);     
      this.save();
      this.calc();
      return producto
    }));
  }
  
  addCart(producto : ProductoCarrito):Observable<ProductoCarrito>{
    return timer(350).pipe(map(() => {
      this.onAddingSubject$.next(producto);
      var cant:number=0;
      var p:string= 'no existe';
      for(let i=0; i < this.products_cart.length; i++) {
        //console.log(this.pedidoArray[i].cantidad);
        if(this.products_cart[i].id==producto.id){
          cant = this.products_cart[i].cantidad; 
          cant=cant+1;
          this.products_cart[i].cantidad=cant;
          p='si existe';
        }

      }
      if(p == 'no existe'){
        
          
        let x: ProductoCarrito;
        x={
          id: producto.id,
          imagen: producto.imagen,
          name: producto.name,
          cantidad: 1,
          price: producto.price
          };
        this.products_cart.push(x);        
          
      
      }
      //this.cart.next(this.products_cart);     
      this.save();
      this.calc();
      return producto
    }));
    
  }
  
  quitarCart(id:number){
    var cant:number=0;
    for(let i=0; i < this.products_cart.length; i++) {
      //console.log(this.pedidoArray[i].cantidad);
      if(this.products_cart[i].id==id){
        cant = this.products_cart[i].cantidad;
        if(1<cant){
          cant=cant-1;
          this.products_cart[i].cantidad=cant;
        }else if(cant==1){
          this.products_cart = this.products_cart.filter(x => x != this.products_cart[i]);
        } 
        
      }

    }
    this.save();
    this.calc();
  }
  
  vaciarCart(){
    this.products_cart = [];
    console.log('vaciando');
    this.save();
    this.calc();
  }
  
  private calc (){
    this.itemsSubject$.next(this.products_cart);
  }
  private save(): void {
    localStorage.setItem('carritoItems', JSON.stringify(this.products_cart));
  }
  private load(): void {
    const items = localStorage.getItem('carritoItems');

    if (items) {
      this.products_cart = JSON.parse(items);
    }
  }
}
