import { Component, Input } from '@angular/core';
import { theme } from '../../../data/theme';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    theme = theme;
    socialLinks = [
        {type: 'facebook',  url: 'https://www.facebook.com/automotrizveler/', icon: 'fab fa-facebook-f'},
        // {type: 'twitter',   url: 'http://www.twitter.com', icon: 'fab fa-twitter'},
        // {type: 'youtube',   url: 'http://www.youtube.com', icon: 'fab fa-youtube'},
        {type: 'instagram', url: 'https://www.instagram.com/automotrizveler.peru/', icon: 'fab fa-instagram'},
        // {type: 'rss',       url: 'http://www.rss.com', icon: 'fas fa-rss'},
        {type: 'rss',       url: 'http://www.tiktok.com/@automotriz.veler?is_from_webapp=1&sender_device=pc/automotrizveler.peru//automotrizveler/', icon: 'fab fa-tiktok'},
    ];

    @Input() image6: string;

    constructor() { }
}
