import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Producto } from '../interfaces/producto';
@Injectable({
  providedIn: 'root'
})
export class BuscardorService {

  API_URI = environment.apiUrl;

  constructor(private http :HttpClient ) { }

  serchProductos(name: string){
    return this.http.get<Producto[]>(`${this.API_URI}/serchProductos/${name}`);
  }
}
