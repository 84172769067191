import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  API_URI = environment.apiUrl;
  constructor(
    private http :HttpClient
  ) { }
  getSidebarFiltros():Observable<any[]>{
    return this.http.get<any[]>(`${this.API_URI}/sidebar`);
  }
  //
  selectClase():Observable<any[]>{
    return this.http.get<any[]>(`${this.API_URI}/selectClase`);
  }
  selectMarca(name: string){
    return this.http.get<any[]>(`${this.API_URI}/selectClase/${name}`);
  }
  getFiltrosClase(name: string):Observable<any[]>{
    return this.http.get<any[]>(`${this.API_URI}/sidebarClase/${name}`);
  }
  selectMarca2():Observable<any[]>{
    return this.http.get<any[]>(`${this.API_URI}/selectMarca2`);
  }
  selectParte():Observable<any[]>{
    return this.http.get<any[]>(`${this.API_URI}/selectParte`);
  }
  selectModelo(name: string){
    return this.http.get<any[]>(`${this.API_URI}/selectModelo/${name}`);
  }
  getFiltrosTodos(name: string):Observable<any[]>{
    return this.http.get<any[]>(`${this.API_URI}/sidebarTodos/${name}`);
  }
}
