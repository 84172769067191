import { Pipe, PipeTransform } from '@angular/core';
import { Producto } from '../../../interfaces/producto';

@Pipe({
  name: 'pagination'
})
export class PaginationPipe implements PipeTransform {

  transform(array: Producto[], page_size: number , page_number: number): Producto[] {
    if (!array.length) return []
    
    page_size = page_size || 5
    page_number = page_number || 1
    --page_number
    
    return array.slice(page_number * page_size, (page_number + 1) * page_size)
  }

}
