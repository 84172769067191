<div class="dropcart__empty" *ngIf="!(cart_items$|async).length">
    ¡Tu Carrito de Compras esta vacio!
</div>
<ng-container *ngIf="(cart_items$|async).length">
    <ul class="dropcart__list">
        <ng-container *ngFor="let item of cart_items$|async">
            <li class="dropcart__item">
                <div class="dropcart__item-image image image--type--product">
                    <a [routerLink]="['/shop/productos',item.id]" class="image__body" (click)="closeMenu.emit()">
                        <img class="image__tag" [src]="item.imagen == '' ? './assets/images/sinimagen.jpg' : URL_ERP+ item.imagen"  alt="">
                    </a>
                </div>
                <div class="dropcart__item-info">
                    <div class="dropcart__item-name">
                        <a [routerLink]="['/shop/productos',item.id]" (click)="closeMenu.emit()">{{ item.name }}</a>
                    </div>
                    <div class="dropcart__item-meta">
                        <div class="dropcart__item-quantity">{{ item.cantidad }}</div>
                        <div class="dropcart__item-price">{{ item.price|currencyFormat }}</div>
                    </div>
                </div>
                <!--
                <button
                    appRemoveFromCart
                    type="button"
                    class="dropcart__item-remove"
                    [class.dropcart__item-remove--loading]="removeFromCart.inProgress"
                    (click)="removeFromCart.remove(item)"
                    #removeFromCart="removeFromCart"
                >
                    <app-icon icon="cross-10"></app-icon>
                </button>
                -->
                <button
                    type="button"
                    class="dropcart__item-remove"
                >
                    <app-icon icon="cross-10"></app-icon>
                </button>
            </li>
            <li class="dropcart__divider" role="presentation"></li>
        </ng-container>
    </ul>
    <div class="dropcart__totals">
        <table>
            <tbody>
            <!--
            <tr *ngIf="(cart.totals$|async).length">
                <th>{{ 'TABLE_SUBTOTAL'|translate }}</th>
                <td>{{ cart.subtotal$|async|currencyFormat }}</td>
            </tr>
            <tr *ngFor="let total of cart.totals$|async">
                <th>{{ 'TABLE_TOTAL_' + total.title|translate }}</th>
                <td>{{ total.price|currencyFormat  }}</td>
            </tr>
            -->
            <tr>
                <th>{{ 'TABLE_TOTAL'|translate }}</th>
                <td>{{ total$|async|currencyFormat }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="dropcart__actions">
        <a routerLink="/shop/carrito" class="btn btn-secondary" (click)="closeMenu.emit()">Ver Carrito</a>
        <!-- <a routerLink="/shop/checkout" class="btn btn-primary" (click)="closeMenu.emit()">{{ 'BUTTON_CHECKOUT'|translate }}</a> -->
    </div>
</ng-container>
