import { Component, OnInit } from '@angular/core';
import { WishlistService } from '../../services/wishlist.service';
import { AccountApi } from '../../api/base';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CartService } from '../../services/cart.service';
import { HeaderService } from '../../services/header.service';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../services/url.service';
import { CarritoService, ProductoCarrito } from '../../services/carrito.service';
import { FiltrosService } from '../shop/services/filtros.service';
import { ProductosTraerService } from '../../services/productos-traer.service';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    email$: Observable<string | null> = this.account.user$.pipe(map(x => x ? x.email : null));

    departmentsLabel$: Observable<string>;
    clasesLabel$: Observable<string>;

    total$: Observable<number>;

    quantity$: Observable<number>;

    constructor(
        private account: AccountApi,
        private translate: TranslateService,
        public wishlist: WishlistService,
        public cart: CartService,
        public header: HeaderService,
        public url: UrlService,
        private carritoService: CarritoService,
        private fil:FiltrosService,
        private productosTraerService:ProductosTraerService
    ) { 
        this.total$=this.carritoService.items$
        .pipe(
        map(items => {
            var sum = 0;
            for (let item of items ){
                sum=sum + item.cantidad * item.price;
            }
            return sum;
        })
        );
        this.quantity$=this.carritoService.items$
        .pipe(
        map(items => {
            var sum = 0;
            for (let item of items ){
                sum=sum + item.cantidad;
            }
            return sum;
        })
        );
        if(this.fil.listInicial.length==0){
            this.productosTraerService.ProductosTraer().subscribe(list=>{
                this.fil.listInicial=list;
            });
        }
    }
    
    ngOnInit(): void {
        this.departmentsLabel$ = this.header.desktopLayout$.pipe(
            switchMap(layout => this.translate.stream(
                layout === 'spaceship' ? 'BUTTON_DEPARTMENTS' : 'BUTTON_DEPARTMENTS_LONG',
            )),
        );

        this.clasesLabel$ = this.header.desktopLayout$.pipe(
            switchMap(layout => this.translate.stream(
                layout === 'spaceship' ? 'BUTTON_CLASES' : 'BUTTON_CLASES_LONG',
            )),
        );
    }
}
