import { Brand } from '../../app/interfaces/brand';

export const brands: Brand[] = [
    {
        slug: 'aim-parts',
        name: 'Frenosa',
        country: 'DE',
        image: 'assets/images/brands/brand-1.png',
    },
    {
        slug: 'wind-engine',
        name: 'Compact',
        country: 'DE',
        image: 'assets/images/brands/brand-2.png',
    },
    {
        slug: 'turbo-electric',
        name: 'Terbon',
        country: 'DE',
        image: 'assets/images/brands/brand-3.png',
    },
    {
        slug: 'start-one',
        name: 'Retenes Willy Bush',
        country: 'DE',
        image: 'assets/images/brands/brand-4.png',
    },
    {
        slug: 'brandix',
        name: 'Nano Max',
        country: 'DE',
        image: 'assets/images/brands/brand-5.png',
    },
    {
        slug: 'abs-brand',
        name: 'Fritec',
        country: 'DE',
        image: 'assets/images/brands/brand-6.png',
    },
    {
        slug: 'great-circle',
        name: 'Asashi',
        country: 'DE',
        image: 'assets/images/brands/brand-7.png',
    },
    {
        slug: 'just-romb',
        name: 'Friccion Automotriz',
        country: 'DE',
        image: 'assets/images/brands/brand-8.png',
    },
    {
        slug: 'fast-wheels',
        name: 'Industria Medina',
        country: 'DE',
        image: 'assets/images/brands/brand-9.png',
    },
    {
        slug: 'stroyka-x',
        name: 'American Roll',
        country: 'DE',
        image: 'assets/images/brands/brand-10.png',
    },
    {
        slug: 'mission-51',
        name: 'Bosh',
        country: 'DE',
        image: 'assets/images/brands/brand-11.png',
    },
    {
        slug: 'fuel-corp',
        name: 'Fremax',
        country: 'DE',
        image: 'assets/images/brands/brand-12.png',
    },
    {
        slug: 'red-gate',
        name: 'Gigante',
        country: 'DE',
        image: 'assets/images/brands/brand-13.png',
    },
    
];
