import { Injectable } from '@angular/core';
import { Producto } from '../interfaces/producto';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { Noticia } from '../interfaces/noticia';
import { Marca } from '../interfaces/marca';
import { MarcaV } from '../interfaces/marcav';

@Injectable({
  providedIn: 'root'
})
export class BlockProductosService {
  API_URI = environment.apiUrl;
  constructor(
    private http :HttpClient
  ) { }
  getProductosDestacados():Observable<Producto[]>{
    return this.http.get<Producto[]>(`${this.API_URI}/productos_destacados`);
  }
  getProductosHomeCarrouuseles(text: string):Observable<Producto[]>{
    return this.http.get<Producto[]>(`${this.API_URI}/productos_destacados/${text}`);
  }
  getBanners(text: string):Observable<Producto[]>{
    // console.log("banners",this.http.get<Producto[]>(`${this.API_URI}/banners`));
    
    return this.http.get<Producto[]>(`${this.API_URI}/banners`);
  }
  getClases(text: string):Observable<Producto[]>{
    // console.log("clases",this.http.get<Producto[]>(`${this.API_URI}/clases`));
    
    return this.http.get<Producto[]>(`${this.API_URI}/clases`);
  }
  getNoticias(text: string):Observable<Noticia[]>{
    // console.log("noticias>> ",this.http.get<Producto[]>(`${this.API_URI}/noticias`));

    return this.http.get<Noticia[]>(`${this.API_URI}/noticias`);
  }
  getNoticia(text: string):Observable<Noticia[]>{
    // console.log("noticias>> ",this.http.get<Producto[]>(`${this.API_URI}/noticias`));

    return this.http.get<Noticia[]>(`${this.API_URI}/noticia/${text}`);
  }
  getMarcas():Observable<Marca[]>{
    // console.log("noticias>> ",this.http.get<Producto[]>(`${this.API_URI}/noticias`));

    return this.http.get<Marca[]>(`${this.API_URI}/marcas`);
  }
  getMarcasV():Observable<MarcaV[]>{
    return this.http.get<MarcaV[]>(`${this.API_URI}/marcasv`);
  }
}
