import {
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
} from '@angular/core';
import { clases } from '../../../../../data/header-clases';
import { ClasesLink } from '../../../../interfaces/clases-link';
// import { departments } from '../../../../../data/header-departments';
// import { DepartmentsLink } from '../../../../interfaces/departments-link';
import { fromOutsideClick } from '../../../../functions/rxjs/from-outside-click';
import { Subject } from 'rxjs';
import { filter, takeUntil, debounceTime } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { BlockProductosService } from 'projects/storefront/src/app/services/block-productos.service';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { SidebarService } from '../../../shop/services/sidebar.service';

// interface ClaseData {
//     id: string;
//     nombre: string;
// }

@Component({
    selector: 'app-clases',
    templateUrl: './clases.component.html',
    styleUrls: ['./clases.component.scss'],
})
export class ClasesComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    isOpen = false;

    // items: ClasesLink[] = clases;
    items: any;

    currentItem;

    @Input() label: string;

    @HostBinding('class.clases') classClases = true;

    @HostBinding('class.clases--open') get classClasesOpen() {
        return this.isOpen;
    }

    clases_lista: any;
    // claseData: ClaseData[] = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private elementRef: ElementRef<HTMLElement>,
        private zone: NgZone,
        private blockProductosService: BlockProductosService,
        private route: ActivatedRoute,
        private router:Router,
        private sidebar: SidebarService
    ) { }

    ngOnInit(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            fromOutsideClick(this.elementRef.nativeElement).pipe(
                filter(() => this.isOpen),
                takeUntil(this.destroy$),
            ).subscribe(() => {
                this.zone.run(() => this.isOpen = false);
            });
        });

        // this.blockProductosService.getClases('').subscribe((responseData) => {
      
        //     this.clases_lista=responseData;
        //     this.items=this.clases_lista;
        // });

        this.sidebar.selectClase().subscribe((responseData) => {
            this.clases_lista=responseData;
            this.items=this.clases_lista;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onClick() {
        this.isOpen = !this.isOpen;
    }

    onMouseenter(item) {
        this.currentItem = item;
    }

    onMouseleave() {
        this.currentItem = null;
    }

    onItemClick(): void {
        this.isOpen = false;
        this.currentItem = null;
    }

    cargar_catalogo_clases(text: string){
    //    var link = `/shop/clase/${text}` + '/productos';
    //    this.router.navigate([link]);

       ///////////////////
    //    this.router.navigate([`/shop/clase/${text}` + '/productos']).then(e =>{
    //     if (e){
    //       console.log("good work")
    //     }else{
    //       console.log("bad work")
    //     }
    //   });

    ////////////////////////

    var link = `/shop/clase/${text}` + '/productos';
        this.router.navigate([link]);
    /*
        this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        debounceTime(5000)
        ).subscribe(
            x => {
            console.log('val',x);
            this.router.navigate([link]);
            // this.router.navigate(['/']); /Redirect to Home/
            
            }
        )
        */
    }
}
