import { Injectable, EventEmitter } from '@angular/core';
import { Producto } from '../../../interfaces/producto';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

export interface Parametros {
  input?:string;
  clase?: string;
  modelo?: string;
  marca?: string;
}
@Injectable({
  providedIn: 'root'
})
export class FiltrosService {
  sidebar_filtros:any[]=[];
  //Cargdor de Filtros en Sidebar
  sidebar_filtros_load:boolean=false;
  //Cargador de Productos
  isLoading:boolean = false;
  listInicial: Producto[] = [];
  ListInicio(parametros:Parametros){
    this.products_catalogo = this.filtrar_parametros(parametros);
    this.listSubject$.next(this.products_catalogo);
  }
  private products_catalogo: Producto[] = [];
  private listSubject$: BehaviorSubject<Producto[]> = new BehaviorSubject(this.products_catalogo);
  readonly list$: Observable<Producto[]> = this.listSubject$.asObservable();
  setList(list: any): void {
    this.products_catalogo = list;
    this.listSubject$.next(this.products_catalogo);
    
  }

  readonly optionsChange$: EventEmitter<string> = new EventEmitter<string>();
  private parametrosState: Parametros = {};
  get options(): Parametros {
    return this.parametrosState;
  } 
  constructor() { }

  setFilterValue(nombre: string, valor: string): void {
    
    this.setOptions(
      {
        ...this.parametrosState,
        [nombre]: valor,
      }
    );
    
  }
  private setOptions(options: any): void {
    console.log(options)
    this.parametrosState = options;
    this.optionsChange$.emit(options);
  }
  filtrar_parametros(parametros: Parametros){
    if(parametros.input){
      return this.listInicial.filter(producto => producto.name.toUpperCase().includes(parametros.input.toUpperCase())) 
    }else{
      var temp:Producto[]=[];
      var cl:string ='';
      var mo :string ='';
      var ma:string ='';
      if(parametros.clase) cl=parametros.clase;
      if(parametros.modelo) mo = parametros.modelo;
      if(parametros.marca) ma = parametros.marca;
      for(let i=0; i < this.listInicial.length; i++) {
        
        if( (this.deserialize(cl).indexOf(this.listInicial[i].clasificacion) >= 0 || cl=='') && (this.deserialize(mo).indexOf(this.listInicial[i].categoria) >= 0 || mo=='') && (this.deserialize(ma).indexOf(this.listInicial[i].marca) >= 0 || ma=='')){
          temp.push(this.listInicial[i]);
        }
      }
  
      console.log(temp)
      return temp;
    }
    
  }
  serialize(value: string[]): string {
    return value.join(',');
  }

  deserialize(value: string): string[] {
      return value !== '' ? value.split(',') : [];
  }
  resetAllFilters(): void {
    this.setOptions({});
  }

}
