<div class="site-footer">
    <app-decor class="site-footer__decor" type="bottom"></app-decor>
    <!-- <div class="site-footer__widgets"style='background: url(../../assets/images/banners/banner_footer.jpg) 100% 100%;'> -->
        <div class="site-footer__widgets"style='background: url({{ image6 }}) 100% 100%;'>
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-4">
                    <app-contacts class="site-footer__widget"></app-contacts>
                </div>
                <div class="col-12 col-md-6 col-xl-4">
                        <app-newsletter class="site-footer__widget"></app-newsletter>
                    </div>
                <div class="col-6 col-md-3 col-xl-2">
                    <app-links
                        class="site-footer__widget"
                        [header]="'Mapa del Sitio'|translate"
                        [links]="[
                            {title: 'Quiénes Somos'|translate, url: '/site/about-us'},
                            {title: 'Productos'|translate, url: '/shop/clases'},
                            {title: 'Carrito de Compras'|translate, url: '/shop/cart'},
                            {title: 'Marcas'|translate, url: '/shop/marcas'},
                            {title: 'Contáctenos'|translate, url: '/site/contactenos'}
                        ]"
                    ></app-links>
                </div>
                <!-- <div class="col-6 col-md-3 col-xl-2">
                    <app-links
                        class="site-footer__widget"
                        [header]="'Mi Cuenta'|translate"
                        [links]="[
                            {title: 'Login'|translate},
                            {title: 'Historial'|translate},
                            {title: 'Mis compras'|translate},
                            {title: 'Servicio Delivery'|translate}
                        ]"
                    ></app-links>
                </div> -->
                <div class="col-6 col-md-3 col-xl-9">

                </div>
                <div class="col-6 col-md-3 col-xl-3">
                    <div class="footer-newsletter__text footer-newsletter__text--social">
                        S&iacute;guenos en nuestras redes sociales
                     
                     <div class="footer-newsletter__social-links social-links">
                         <ul class="social-links__list">
                             <li *ngFor="let link of socialLinks" class="social-links__item social-links__item--{{ link.type }}">
                                 <a href="{{ link.url }}" target="_blank"><i [ngClass]="link.icon"></i></a>
                             </li>
                         </ul>
                     </div>
                </div>
                <br>
                
            </div>
        </div>
    </div>
    <div class="site-footer__bottom" style='    background: rgb(255 45 41 / 0.4);'>
        <div class="container">
            <div class="site-footer__bottom-row">
                <div class="site-footer__copyright">
                    <!-- copyright -->
                    Todos los derechos reservados <a href="#" target="_blank">.</a> —
                    Diseñado por <a href="http://erpsoluciones.com" target="_blank">ERPSOLUCIONES</a>
                    <!-- copyright / end -->
                </div>
                <div class="site-footer__payments">
                    <img src="assets/images/payments2.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
