<div class="header" style='height: 130px;'>
    <div class="header__megamenu-area megamenu-area"></div>
    <ng-container *ngIf="header.desktopLayout === 'spaceship'">
        <div class="header__topbar-start-bg"></div>
        <div class="header__topbar-start">
            <app-topbar layout="spaceship-start"></app-topbar>
        </div>
        <div class="header__topbar-end-bg"></div>
        <div class="header__topbar-end">
            <app-topbar layout="spaceship-end"></app-topbar>
        </div>
    </ng-container>
    <ng-container *ngIf="header.desktopLayout === 'classic'">
        <div class="header__topbar-classic-bg"></div>
        <div class="header__topbar-classic">
            <app-topbar layout="classic"></app-topbar>
        </div>
    </ng-container>
    <div class="header__navbar">

        <!-- <div class="header__navbar-departments">
            <app-departments [label]="departmentsLabel$|async"></app-departments>
        </div> -->
        <div class="header__navbar-departments">
            <!-- <app-departments [label]="departmentsLabel$|async"></app-departments> -->
            <app-clases [label]="clasesLabel$|async"></app-clases>
        </div>
        <div class="header__navbar-menu">
            <app-main-menu></app-main-menu>
        </div>
        <div *ngIf="header.desktopLayout === 'classic'" class="header__navbar-phone phone">
            <a [routerLink]="url.contacts()" class="phone__body">
                <div class="phone__title">{{ 'TEXT_CALL_US'|translate }}</div>
                <div class="phone__number">800 060-0730</div>
            </a>
        </div>
    </div>
    <app-logo class="header__logo"></app-logo>
    <div class="header__search">
        <app-search></app-search>
    </div>
    <div class="header__indicators">
        <app-indicator
            link="/shop/wishlist"
            icon="heart-32"
            [counter]="wishlist.count$|async"
        ></app-indicator>
        <!-- <app-indicator
            link="/account"
            icon="person-32"
            [label]="(email$|async) || ('TEXT_INDICATOR_ACCOUNT_LABEL'|translate)"
            [value]="'TEXT_INDICATOR_ACCOUNT_VALUE'|translate"
            trigger="click"
            #accountIndicator="indicator"
        >
            <app-account-menu (closeMenu)="accountIndicator.close()"></app-account-menu>
        </app-indicator> -->
        
        <app-indicator
            link="/shop/cart"
            icon="cart-32"
            [label]="'TEXT_INDICATOR_CART_LABEL'|translate"
            [value]="total$|async|currencyFormat"
            [counter]="quantity$|async"
            trigger="click"
            #cartIndicator="indicator"
        >
            <app-dropcart (closeMenu)="cartIndicator.close()"></app-dropcart>
        </app-indicator>
        
    </div>
</div>
