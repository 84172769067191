<ng-container
    appAgregarAlCarrito
    #cart="agregarAlCarrito"
>
    <!--
    <div class="product-card__actions-list">
        <button
            class="product-card__action product-card__action--quickview"
            type="button"
            [class.product-card__action--loading]="showingQuickview"
            [attr.aria-label]="'BUTTON_QUICKVIEW'|translate"
            (click)="showQuickview()"
        >
            <app-icon icon="quickview-16"></app-icon>
        </button>
        <ng-container *ngIf="!exclude.includes('actions')">
            <button
                type="button"
                class="product-card__action product-card__action--wishlist"
                [class.product-card__action--loading]="wishlist.inProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_WISHLIST'|translate"
                (click)="wishlist.add(product)"
            >
                <app-icon icon="wishlist-16"></app-icon>
            </button>
            <button
                type="button"
                class="product-card__action product-card__action--compare"
                [class.product-card__action--loading]="compare.inProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_COMPARE'|translate"
                (click)="compare.add(product)"
            >
                <app-icon icon="compare-16"></app-icon>
            </button>
        </ng-container>
    </div>
    -->
    <div class="product-card__image">
        <div class="image image--type--product">
            <!--<a [routerLink]="url.product(product)" class="image__body">-->
            <a [routerLink]="['/shop/productos',product.id]" class="image__body">
                <img 
                class="image__tag" 
                [src]="product.imagen == '' ? './assets/images/sinimagen.jpg' : URL_ERP+ product.imagen" 
                alt=""
                >
            </a>
        </div>
        <!--
        <app-status-badge
            *ngIf="!exclude.includes('status-badge') && vehicle"
            class="product-card__fit"
            [type]="{
                'all': 'unknown',
                'fit': 'success',
                'not-fit': 'failure',
                'unknown': 'warning'
            }[compatibility()]"
            [icon]="{
                'all': 'success',
                'fit': 'success',
                'not-fit': 'failure',
                'unknown': 'failure'
            }[compatibility()]"
            [text]="{
                'all': 'TEXT_COMPATIBILITY_ALL',
                'fit': 'TEXT_COMPATIBILITY_FIT',
                'not-fit': 'TEXT_COMPATIBILITY_NOT_FIT',
                'unknown': 'TEXT_COMPATIBILITY_UNKNOWN'
            }[compatibility()]|translate:{year: vehicle.year, make: vehicle.make, model: vehicle.model}"
            [tooltipContent]="{
                'all': 'TEXT_COMPATIBILITY_ALL',
                'fit': 'TEXT_COMPATIBILITY_FIT',
                'not-fit': 'TEXT_COMPATIBILITY_NOT_FIT',
                'unknown': 'TEXT_COMPATIBILITY_UNKNOWN'
            }[compatibility()]|translate:{year: vehicle.year, make: vehicle.make, model: vehicle.model}"
        ></app-status-badge>
        -->
    </div>

    <div class="product-card__info">
        <div *ngIf="!exclude.includes('meta')" class="product-card__meta">
            <span class="product-card__meta-title">SKU:</span> {{ product.cod_prod }}
        </div>
        <div class="product-card__name">
            <div>
                <!--
                <div *ngIf="product?.badges.length > 0" class="product-card__badges">
                    <div *ngFor="let badge of product.badges" class="tag-badge tag-badge--{{ badge }}">{{ badge }}</div>
                </div>
                -->
                <!--<a [routerLink]="url.product(product)">{{ product.name }}</a>-->
                <a [routerLink]="['/shop/productos',product.id]">{{ product.name }}</a>
            </div>
        </div>
        <div  class="product-card__rating">
            <app-rating class="product-card__rating-stars" [value]="4"></app-rating>
            <div class="product-card__rating-label"> 
                3 reviews 
            </div>
        </div>
        <!--
        <div class="product-card__rating">
            <app-rating class="product-card__rating-stars" [value]="product.rating"></app-rating>
            <div class="product-card__rating-label">
                {{ 'TEXT_RATING_LABEL'|translate:{rating: product.rating, reviews: product.reviews} }}
            </div>
        </div>
        -->
        <div class="product-card__features">
            <ul>
                <li>Clase: {{product.clasificacion}}</li>
                <li>Calidad: {{product.categoria}}</li>
                <li>Categoria: {{product.subcategoria}}</li>
                <li>Marca: {{product.marca}}</li>
            </ul>
        </div>
        
        <div class="product-card__features">
            <ul>
                <li >
                    parte:
                    <ng-container *ngFor="let value of product.filtro4">
                        {{ value }}
                    </ng-container>
                </li>
            </ul>
        </div>
        <div class="product-card__features">
            <ul>
                <li >
                    Vehiculo:
                    <ng-container *ngFor="let value of product.filtro5">
                        {{ value }}
                    </ng-container>
                </li>
            </ul>
        </div>
        <div class="product-card__features">
            <ul>
                <li >
                    Modelo:
                    <ng-container *ngFor="let value of product.filtro6">
                        {{ value }}
                    </ng-container>
                </li>
            </ul>
        </div>
        
        
    </div>

    <div class="product-card__footer">
        <div class="product-card__prices">
            <!--
            <ng-container *ngIf="product.compareAtPrice">
                <div class="product-card__price product-card__price--new">{{ product.price|currencyFormat }}</div>
                <div class="product-card__price product-card__price--old">{{ product.compareAtPrice|currencyFormat }}</div>
            </ng-container>
            <ng-container *ngIf="!product.compareAtPrice">
                <div class="product-card__price product-card__price--current">{{ product.price|currencyFormat }}</div>
            </ng-container>
            -->
            <div class="product-card__price product-card__price--current">{{ product.price|currencyFormat }}</div>
        </div>
        
        <ng-container *ngIf="!exclude.includes('buttons')">
            <button
                type="button"
                class="product-card__addtocart-icon"
                [class.product-card__addtocart-icon--loading]="cart.inProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_CART'|translate"
                (click)="cart.add(product)"
            >
                <app-icon icon="cart-20"></app-icon>
            </button>
            <ng-container *ngIf="!exclude.includes('list-buttons')">
                <button
                    type="button"
                    class="product-card__addtocart-full"
                    [class.product-card__addtocart-full--loading]="cart.inProgress"
                    (click)="cart.add(product)"
                >
                    Añadir
                </button>
                <button
                    type="button"
                    class="product-card__wishlist"
                >
                    <app-icon icon="wishlist-16"></app-icon>
                    <span>{{ 'BUTTON_ADD_TO_WISHLIST'|translate }}</span>
                </button>
                <button
                    type="button"
                    class="product-card__compare"
                >
                    <app-icon icon="compare-16"></app-icon>
                    <span>{{ 'BUTTON_ADD_TO_COMPARE'|translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
