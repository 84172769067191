export const theme = {
    name: 'RedParts',
    url: 'https://themeforest.net/item/redparts-auto-parts-angular-template/27087440',
    author: {
        name: 'Kos',
        profile_url: 'https://themeforest.net/user/kos9',
    },
    contacts: {
        // address: ['CAL.10 DE DICIEMBRE MZA. F LOTE. 2 URB. NAVIDAD DE VILLA LIMA - LIMA - CHORRILLOS'],
        address: ['CAL.A MZA. P1 LOTE. 3 COO. AMERICA LIMA - LIMA - SAN JUAN DE MIRAFLORES'],
        email: ['ventas@automotrizveler.com info@automotrizveler.com'],
       /* phone: ['(01) 687-9690','943669482'],*/
        phone: ['(01) 396-1460   /  943669482'],
        hours: ['Lun-Sab 9:00am - 6:00pm'],
    },
    contacts2: {
        address: ['AV. DEFENSORES DE LIMA NRO. 1022, SAN JUAN DE MIRAFLORES 15809'],
        email: ['ventas@automotrizveler.com','info@automotrizveler.com','importaciones@automotrizveler.com'],
        phone: ['(01) 322-1796','943669482'],
        hours: ['Lun-Sab 9:00am - 6:00pm'],
    },
    contacts3: {
        address: ['MZ. T LT 28. URB. ALAMEDA DE ATE 2 ETAPA, SANTA ANITA LIMA-LIMA.'],
        email: ['ventas@automotrizveler.com','info@automotrizveler.com','importaciones@automotrizveler.com'],
        phone: ['(01) 322-1796','943669482'],
        hours: ['Lun-Sab 9:00am - 6:00pm'],
    },
};
