<div class="vehicle-select__list" [formGroup]="form">
    <!--
    <ng-container *ngIf="fil.sidebar_filtros_load; else cargaronselects">
        <div 
        class="vehicle-select__item"
        [class.vehicle-select__item--loading]="fil.sidebar_filtros_load"
        >
            <select class="vehicle-select__item-control">
                <option value="none"></option>
            </select>
            <div class="vehicle-select__item-loader"></div>
        </div>
        <div 
        class="vehicle-select__item"
        [class.vehicle-select__item--loading]="fil.sidebar_filtros_load"
        >
            <select class="vehicle-select__item-control">
                <option value="none"></option>
            </select>
            <div class="vehicle-select__item-loader"></div>
        </div>
        <div 
        class="vehicle-select__item"
        [class.vehicle-select__item--loading]="fil.sidebar_filtros_load"
        >
            <select class="vehicle-select__item-control">
                <option value="none"></option>
            </select>
            <div class="vehicle-select__item-loader"></div>
        </div>
    </ng-container>
    <ng-template #cargaronselects>
        <div
        *ngFor="let item of selects"
        class="vehicle-select__item"
        >
            <ng-container *ngIf="item.name === 'Clases'">
                <select
                class="vehicle-select__item-control"
                [attr.aria-label]="item.name"
                formControlName="clase"
                >
                    <option value="">Seleccione {{item.name}}</option>
                    <option *ngFor="let option of item.childrens" [value]="option.des_clas">
                        {{ option.des_clas }}
                    </option>
                </select>
            </ng-container>
            <ng-container *ngIf="item.name === 'Modelos'">
                <select
                class="vehicle-select__item-control"
                [attr.aria-label]="item.name"
                formControlName="modelo"
                >
                    <option value="">Seleccione {{item.name}}</option>
                    <option *ngFor="let option of item.childrens" [value]="option.des_cat">
                        {{ option.des_cat }}
                    </option>
                </select>
            </ng-container>
            <ng-container *ngIf="item.name === 'Marcas'">
                <select
                class="vehicle-select__item-control"
                [attr.aria-label]="item.name"
                formControlName="marca"
                >
                    <option value="">Seleccione {{item.name}}</option>
                    <option *ngFor="let option of item.childrens" [value]="option.nombre">
                        {{ option.nombre }}
                    </option>
                </select>
            </ng-container>
            
            
        </div>
    </ng-template>
-->


<div 
    class="vehicle-select__item"
    [class.vehicle-select__item--loading]="clases.length==0" *ngIf="clases.length==0"
    >
        <select class="vehicle-select__item-control">
            <option value="none"></option>
        </select>
        <div class="vehicle-select__item-loader"></div>
    </div>
    <div class="vehicle-select__item" *ngIf="partes.length>0">
        <select
        class="vehicle-select__item-control"
        formControlName="parte"
        >
            <option value="">Seleccione Parte</option>
            <option *ngFor="let option of partes" [value]="option.descripcion">
                {{ option.descripcion }}
            </option>
        </select>
    </div>


    <div 
    class="vehicle-select__item"
    [class.vehicle-select__item--loading]="marcas2.length==0" *ngIf="marcas2.length==0"
    >
        <select class="vehicle-select__item-control">
            <option value="none"></option>
        </select>
        <div class="vehicle-select__item-loader"></div>
    </div>
    <div class="vehicle-select__item" *ngIf="marcas2.length>0">
        <select
        class="vehicle-select__item-control"
        formControlName="marca2"
        >
            <option value="">Seleccione Marca Vehículo</option>
            <option *ngFor="let option of marcas2" [value]="option.descripcion">
                {{ option.descripcion }}
            </option>
        </select>
    </div>


    

    <!-- <div 
    class="vehicle-select__item"
    [class.vehicle-select__item--loading]="clases.length==0" *ngIf="clases.length==0"
    >
        <select class="vehicle-select__item-control">
            <option value="none"></option>
        </select>
        <div class="vehicle-select__item-loader"></div>
    </div>
    <div class="vehicle-select__item" *ngIf="clases.length>0">
        <select
        class="vehicle-select__item-control"
        formControlName="clase"
        >
            <option value="">Seleccione Clases</option>
            <option *ngFor="let option of clases" [value]="option.des_clas">
                {{ option.des_clas }}
            </option>
        </select>
    </div> -->


    <!-- <div 
    class="vehicle-select__item" *ngIf="disabled_marca"
    >
        <select 
        class="vehicle-select__item-control" 
        [disabled]="true"
        >
            <option value="">Seleccione Marcas</option>
        </select>
    </div>
    <div 
    class="vehicle-select__item"
    [class.vehicle-select__item--loading]="cla" *ngIf="!disabled_marca"
    >
        <select 
        class="vehicle-select__item-control" 
        formControlName="marca"
        >
            <option value="">Seleccione Marcas</option>
            <option *ngFor="let option of marcas" [value]="option.nombre">
                {{ option.nombre }}
            </option>
        </select>
        <div class="vehicle-select__item-loader"></div>
    </div> -->

    <div 
    class="vehicle-select__item" *ngIf="disabled_marca"
    >
        <select 
        class="vehicle-select__item-control" 
        [disabled]="true"
        >
            <option value="">Seleccione Modelo</option>
        </select>
    </div>
    <div 
    class="vehicle-select__item"
    [class.vehicle-select__item--loading]="cla" *ngIf="!disabled_marca"
    >
        <select 
        class="vehicle-select__item-control" 
        formControlName="modelo"
        >
            <option value="">Seleccione Modelo</option>
            <option *ngFor="let option of modelos" [value]="option.descripcion">
                {{ option.descripcion }}
            </option>
        </select>
        <div class="vehicle-select__item-loader"></div>
    </div>


    


</div>
