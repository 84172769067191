import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { CartService } from '../../../../services/cart.service';
import { UrlService } from '../../../../services/url.service';
import { CarritoService, ProductoCarrito } from '../../../../services/carrito.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'projects/storefront/src/environments/environment';

@Component({
    selector: 'app-dropcart',
    templateUrl: './dropcart.component.html',
    styleUrls: ['./dropcart.component.scss'],
})
export class DropcartComponent {
    URL_ERP = environment.urlErp;
    
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class.dropcart') classDropcart = true;

    total$: Observable<number>;

    cart_items$: Observable<ProductoCarrito[]>;

    constructor(
        public cart: CartService,
        public url: UrlService,
        private carritoService: CarritoService
    ) { 
        this.cart_items$=this.carritoService.items$;
        this.total$=this.carritoService.items$
        .pipe(
        map(items => {
            var sum = 0;
            for (let item of items ){
                sum=sum + item.cantidad * item.price;
            }
            return sum;
        })
        );
    }
}
