import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DesktopHeaderVariant, HeaderService, MobileHeaderVariant } from '../../services/header.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'projects/storefront/src/environments/environment';
import { BlockProductosService } from '../../services/block-productos.service';


export interface RootComponentData {
    desktopHeader: DesktopHeaderVariant;
    mobileHeader: MobileHeaderVariant;
}


@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> =  new Subject<void>();

    imagen6:string;
    URL_ERP=environment.urlErp;
    imgBanner6: any;

    constructor(
        private route: ActivatedRoute,
        public header: HeaderService,
        private blockProductosService: BlockProductosService
    ) { }

    ngOnInit(): void {
        this.route.data.pipe(
            takeUntil(this.destroy$),
        ).subscribe((data: RootComponentData) => {
            this.header.setDesktopVariant(data.desktopHeader || 'spaceship/one');
            this.header.setMobileVariant(data.mobileHeader || 'one');
        });

        this.blockProductosService.getBanners('').subscribe((responseData) => {
            this.imgBanner6=responseData[5]; 
            this.imagen6=`${this.URL_ERP}${this.imgBanner6.imagen}`;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
